import { Edit } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Spinner } from '@nextui-org/react';
import type { UpdateProposalRequest } from '@services/proposals/types';
import { ProposalStatus } from '@services/proposals/types';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type { Dispatch, FC, SetStateAction } from 'react';
import { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { getProposalV2, updateProposal } from 'src/api/proposal';
import * as S from './styles';

type ModalEditProposalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  proposal: Awaited<ReturnType<typeof getProposalV2>>;
  setProposal: Dispatch<SetStateAction<Awaited<ReturnType<typeof getProposalV2>> | undefined>>;
};

export const ModalEditProposal: FC<ModalEditProposalProps> = function ModalEditProposal({
  open,
  setOpen,
  proposal,
  setProposal,
}) {
  const [querying, setQuerying] = useState<boolean>(false);
  const [newExpirationDate, setNewExpirationDate] = useState<Dayjs>(
    dayjs(proposal.proposalExpirationDate),
  );
  const [newImplementationFee, setNewImplementationFee] = useState<number>(
    proposal.implementationFee,
  );
  const [newFinalImplementationFee, setNewFinalImplementationFee] = useState<number>(
    proposal.finalImplementationFee,
  );
  const [newFinalPlatformFee, setNewFinalPlatformFee] = useState<number>(proposal.finalPlatformFee);
  const [newAverageMonthlyReward, setNewAverageMonthlyReward] = useState<number>(
    proposal.averageMonthlyReward,
  );
  const [newTotalEmployeeAmount, setNewTotalEmployeeAmount] = useState<number>(
    proposal.totalEmployee,
  );

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  async function handleSave() {
    try {
      setQuerying(true);

      const updateProposalRequest: UpdateProposalRequest = {
        id: proposal.id,
        affinityOpportunityId: proposal.affinityOpportunityId ?? undefined,
      };

      if (proposal.proposalExpirationDate !== new Date(newExpirationDate.toDate()))
        updateProposalRequest.proposalExpirationDate = new Date(newExpirationDate.toDate());
      if (proposal.implementationFee !== newImplementationFee)
        updateProposalRequest.implementationFee = newImplementationFee;
      if (proposal.finalImplementationFee !== newFinalImplementationFee)
        updateProposalRequest.finalImplementationFee = newFinalImplementationFee;
      if (proposal.finalPlatformFee !== newFinalPlatformFee)
        updateProposalRequest.finalPlatformFee = newFinalPlatformFee;
      if (proposal.averageMonthlyReward !== newAverageMonthlyReward)
        updateProposalRequest.averageMonthlyReward = newAverageMonthlyReward;
      if (proposal.totalEmployee !== newTotalEmployeeAmount)
        updateProposalRequest.totalEmployee = newTotalEmployeeAmount;

      await updateProposal(updateProposalRequest);
      setOpen(false);
      setProposal(await getProposalV2(proposal.affinityOpportunityId ?? 0));
      toast.success('Proposal updated successfully');
    } catch (error) {
      toast.error('Error while updating proposal');
    } finally {
      setQuerying(false);
    }
  }

  function pendingApprovalEditable() {
    return (
      proposal.status === ProposalStatus.OPEN ||
      proposal.status === ProposalStatus.EXPIRED ||
      proposal.status === ProposalStatus.SIGNED
    );
  }

  function openEditable() {
    return proposal.status === ProposalStatus.SIGNED;
  }

  return (
    <Fragment>
      <S.EditButton onClick={handleClickOpen} variant="outlined">
        <Edit />
        <span>Edit</span>
      </S.EditButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Proposal Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Editing these values will also update the Opportunity in Affinity
          </DialogContentText>
          <S.FieldContainer>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  sx={{ width: '100%' }}
                  label="Expiration"
                  defaultValue={newExpirationDate}
                  disabled={openEditable()}
                  onChange={(value) => {
                    if (value) setNewExpirationDate(value);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </S.FieldContainer>
          <S.FieldContainer>
            <TextField
              label="Original Implementation Fee"
              value={newImplementationFee}
              onChange={(event) => setNewImplementationFee(parseFloat(event.target.value))}
              disabled={pendingApprovalEditable()}
              fullWidth
              variant="outlined"
              type="number"
            />
          </S.FieldContainer>
          <S.FieldContainer>
            <TextField
              label="Actual Implementation Fee"
              value={newFinalImplementationFee}
              onChange={(event) => setNewFinalImplementationFee(parseFloat(event.target.value))}
              disabled={pendingApprovalEditable()}
              fullWidth
              variant="outlined"
              type="number"
            />
          </S.FieldContainer>
          <S.FieldContainer>
            <TextField
              label="Actual Platform Fee"
              value={newFinalPlatformFee}
              onChange={(event) => setNewFinalPlatformFee(parseFloat(event.target.value))}
              disabled={pendingApprovalEditable()}
              fullWidth
              variant="outlined"
              type="number"
            />
          </S.FieldContainer>
          <S.FieldContainer>
            <TextField
              InputLabelProps={{ shrink: true }}
              label="Avg. Rewards Budget"
              value={newAverageMonthlyReward}
              onChange={(event) => setNewAverageMonthlyReward(parseFloat(event.target.value))}
              disabled={pendingApprovalEditable()}
              fullWidth
              variant="outlined"
              type="number"
            />
          </S.FieldContainer>
          <S.FieldContainer>
            <TextField
              label="Employee Amount"
              value={newTotalEmployeeAmount}
              disabled={pendingApprovalEditable()}
              onChange={(event) => setNewTotalEmployeeAmount(parseFloat(event.target.value))}
              fullWidth
              variant="outlined"
              type="number"
            />
          </S.FieldContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>{querying ? <Spinner></Spinner> : 'Save'}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
