import type { User } from 'src/context/userContext';

export const setToken = (token: string) => {
  localStorage.setItem('jwtToken', token);
};

export const setUserOnLocalStorage = (user: User) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const clearUserOnLocalStorage = () => {
  localStorage.removeItem('user');
};

export const getUser = () => {
  const stringifyUser = localStorage.getItem('user');

  if (stringifyUser === null) return null;

  return JSON.parse(stringifyUser);
};

export const getToken = () => localStorage.getItem('jwtToken');

export const removeToken = () => {
  localStorage.removeItem('jwtToken');
};

export const isAuthenticated = () => !!getToken();

export const checkAuth = () => {
  if (!isAuthenticated()) {
    window.location.href = '/login';
  }
};
