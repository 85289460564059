import GoBackButton from '@components/GoBackButton';
import PageTitle from '@components/PageTitle';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  RadioGroup,
  Spinner,
  Textarea,
} from '@nextui-org/react';
import TagTable from '@pages/Agencies/components/TagTable';
import { smsOptions } from '@pages/Agencies/utils';
import { getAgencyDetails } from '@services/agencies';
import type { AgencyDetailsObject } from '@services/agencies/types';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { editSmsJob, getSmsJobs } from 'src/api/smsJob';
import type { EditSmsFormProps } from 'src/api/smsJob/types';
import CustomInput from '../NewSms/CustomInput';
import CustomRadio from '../NewSms/CustomRadio';
import { SmsAudience } from '../NewSms/types';

const AgencyEditSms = () => {
  const [agency, setAgency] = useState<AgencyDetailsObject>();
  const [audience, setAudience] = useState<SmsAudience>(SmsAudience.ALL);
  const [date, setDate] = useState<string>('');
  const [time, setTime] = useState<string>('');
  const [dateTime, setDateTime] = useState<Date>();
  const [message, setMessage] = useState<string>('');
  const [querying, setQuerying] = useState<boolean>(false);
  const [onError, setOnError] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();
  const { id, jobId } = params;

  const validate = () => date !== '' && time !== '' && message !== '';

  const getAgency = async () => {
    if (!id) return;

    const agencyResponse = await getAgencyDetails(id);

    if (agencyResponse) setAgency(agencyResponse);
  };

  const getSmsInfo = async () => {
    if (!agency?.publicId || !jobId) return;

    const smsList = await getSmsJobs(agency.publicId);
    const smsInfo = smsList.find((sms) => sms.jobId === jobId);

    if (smsInfo) {
      setAudience(SmsAudience.ALL);

      const validValues = Object.values(SmsAudience) as string[];

      if (validValues.includes(smsInfo.filterType as string)) {
        setAudience(smsInfo.filterType as SmsAudience);
      }

      const smsDate = new Date(smsInfo.date);
      const formattedDate = moment(smsDate).format('YYYY-MM-DD');

      setDate(formattedDate);

      const formattedTime = moment(smsDate).format('HH:mm');

      setTime(formattedTime);
      setDateTime(new Date(smsInfo.date));
      setMessage(smsInfo.message);
    }
  };

  async function submit() {
    if (!jobId) return;

    if (!validate()) {
      setOnError(true);

      return;
    }

    try {
      setQuerying(true);

      if (dateTime == null) {
        setOnError(true);

        return;
      }

      const editSmsForm: EditSmsFormProps = {
        audience,
        date: dateTime,
        message,
      };

      await editSmsJob(jobId, editSmsForm);
      navigate(`/agencies/${id}/sms-manager`);
      toast.success('SMS succesfully updated');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      toast.error('Error while updating SMS');
    } finally {
      setQuerying(false);
    }
  }

  useEffect(() => {
    getAgency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getSmsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency, jobId]);

  const onChangeAudience = (value: string) => {
    const validValues = Object.values(SmsAudience) as string[];

    if (validValues.includes(value)) {
      setAudience(value as SmsAudience);
    }
  };

  useEffect(() => {
    if (date === '' || time === '') return;

    const dateTimeString = `${date}T${time}:00`;
    const dateObj = new Date(dateTimeString);

    setDateTime(dateObj);
  }, [date, time]);

  return (
    <div className="flex flex-col w-full gap-5">
      <GoBackButton label="SMS list" navigatePath={`/agencies/${id}/sms-manager`} />
      <div className="flex flex-row justify-between">
        <PageTitle>Update SMS for {agency?.name}</PageTitle>
        <Button
          onClick={submit}
          style={{
            background: '#6558F5',
            color: 'white',
          }}
        >
          {querying ? <Spinner color="white" /> : 'Update SMS'}
        </Button>
      </div>
      <div className="flex flex-col gap-5">
        <Card className="">
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">Who do you want to send the message to?</span>
          </CardHeader>
          <CardBody className="pt-0">
            <RadioGroup
              defaultValue={'all'}
              color="secondary"
              value={audience}
              onValueChange={onChangeAudience}
            >
              {smsOptions.map((option) => (
                <CustomRadio value={option.value} description={option.description}>
                  {option.label}
                </CustomRadio>
              ))}
            </RadioGroup>
          </CardBody>
        </Card>
        <Card className="">
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">When do you want to send it?</span>
          </CardHeader>
          <CardBody className="pt-0">
            <div className="flex flex-col gap-3 mt-2">
              <CustomInput
                type="date"
                label="Date"
                labelPlacement="outside-left"
                value={date}
                onValueChange={setDate}
                isInvalid={onError && date === ''}
                errorMessage={onError && date === '' ? 'Date is required' : undefined}
              />
              <CustomInput
                type="time"
                label="Time"
                labelPlacement="outside-left"
                value={time}
                onValueChange={setTime}
                isInvalid={onError && time === ''}
                errorMessage={onError && time === '' ? 'Time is required' : undefined}
              />
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="flex flex-row gap-5 mb-5">
        <Card className="w-full">
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">Message</span>
          </CardHeader>
          <CardBody className="pt-0">
            <Textarea
              variant="bordered"
              placeholder="Write your message here"
              value={message}
              onValueChange={setMessage}
              minRows={8}
              isInvalid={onError && message === ''}
              errorMessage={onError && message === '' ? 'Message is required' : undefined}
            />
          </CardBody>
        </Card>
        <Card className="w-full">
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">Tags</span>
          </CardHeader>
          <CardBody className="pt-0">
            <TagTable />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AgencyEditSms;
