"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferralFactory = void 0;
const faker_1 = require("@faker-js/faker");
const luxon_1 = require("luxon");
const uuid_1 = require("uuid");
const agency_1 = require("./agency");
const agencyJobPosting_1 = require("./agencyJobPosting");
const generated_1 = require("./generated");
const profile_1 = require("./profile");
exports.ReferralFactory = (0, generated_1.defineReferralFactory)({
    defaultData: async () => ({
        agency: agency_1.AgencyFactory,
        agencyJobPosting: agencyJobPosting_1.AgencyJobPostingFactory,
        publicId: (0, uuid_1.v4)(),
        firstName: faker_1.faker.person.firstName(),
        surname: faker_1.faker.person.lastName(),
        email: faker_1.faker.internet.email(),
        resumeUrl: faker_1.faker.internet.url(),
        phoneNumber: faker_1.faker.phone.number(),
        step: 'SUBMITTED',
        created: luxon_1.DateTime.now().toJSDate(),
        lastUpdate: luxon_1.DateTime.now().toJSDate(),
        answers: [
            { type: 'YES_NO', answer: 'NO', question: 'Do you have valid drivers license?' },
            { type: 'YES_NO', answer: 'YES', question: 'Do you have your own reliable transportation?' },
        ],
        inviteCode: (0, uuid_1.v4)(),
        inviteClaimed: true,
        referrerDismissed: false,
        externalId: null,
        ermProfileId: null,
        hiredDate: null,
        state: null,
        city: null,
        zipCode: null,
        isArchived: false,
        called: false,
        isRejected: false,
        referrer: profile_1.ProfileFactory,
        milestonesCompleted: {
            milestones: [
                {
                    durationValue: 30,
                    type: 'Hours',
                    points: 2000,
                    created_at: luxon_1.DateTime.utc().toJSDate(),
                },
            ],
        },
    }),
});
