import PageTitle from '@components/PageTitle';
import { Delete } from '@mui/icons-material';
import {
  Button,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  getKeyValue,
  useDisclosure,
} from '@nextui-org/react';
import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getProposalContactList } from 'src/api/proposalContact';
import type { ProposalContact } from 'src/api/proposalContact/types';
import { ModalConfirmDelete } from './components/modalConfirmDelete';
import { ModalCreateProposalContact } from './components/modalCreateProposalContact';
import * as S from './styles';

export function ProposalContactPage() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [proposalContactList, setProposalContactList] = useState<ProposalContact[]>([]);
  const [selectedProposalContact, setSelectedProposalContact] = useState<ProposalContact>();
  const {
    isOpen: isOpenDeleteContact,
    onOpen: onOpenDeleteContact,
    onOpenChange: onOpenChangeDeleteModal,
  } = useDisclosure();
  const {
    isOpen: isOpenCreateContactModal,
    onOpen: onOpenCreateContact,
    onOpenChange: onOpenChangeCreateModal,
  } = useDisclosure();

  function refresh() {
    setIsLoading(true);
    getProposalContactList().then((result) => {
      setProposalContactList(result);
      setIsLoading(false);
    });
  }

  useEffect(() => refresh(), []);

  return (
    <Fragment>
      <S.Container>
        <PageTitle>Proposal Contact</PageTitle>
        <Button
          onClick={onOpenCreateContact}
          style={{
            background: '#6558F5',
            color: 'white',
          }}
        >
          New Proposal Contact
        </Button>
      </S.Container>
      <Table aria-label="Agencies table" isStriped shadow="md">
        <TableHeader>
          <TableColumn className="bg-purple-500 text-white">Name</TableColumn>
          <TableColumn className="bg-purple-500 text-white">Email</TableColumn>
          <TableColumn className="bg-purple-500 text-white">Position</TableColumn>
          <TableColumn className="bg-purple-500 text-white">Phone number</TableColumn>
          <TableColumn className="bg-purple-500 text-white">Assigned contracts</TableColumn>
          <TableColumn className="bg-purple-500 text-white">Actions</TableColumn>
        </TableHeader>
        <TableBody
          isLoading={isLoading}
          loadingContent={<Spinner color="secondary" />}
          emptyContent={'No rows to display.'}
        >
          {proposalContactList.map((contact) => (
            <TableRow key={`proposal-${contact.name}`}>
              <TableCell>{getKeyValue(contact, 'name')}</TableCell>
              <TableCell>{getKeyValue(contact, 'email')}</TableCell>
              <TableCell>{getKeyValue(contact, 'position')}</TableCell>
              <TableCell>{getKeyValue(contact, 'phoneNumber')}</TableCell>
              <TableCell>
                {contact.proposals && (
                  <ul>
                    {contact.proposals.map((proposal) => (
                      <li key={`contact-proposal-${proposal.name}`}>
                        <b>{proposal.name}</b>
                      </li>
                    ))}
                  </ul>
                )}
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => {
                    if (contact.proposals && contact.proposals.length > 0) {
                      toast.error(
                        "Error: You can't delete a contact that is assigned to a proposal",
                      );

                      return;
                    }

                    setSelectedProposalContact(contact);
                    onOpenDeleteContact();
                  }}
                >
                  <Delete />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {selectedProposalContact && (
        <ModalConfirmDelete
          refreshFunc={refresh}
          isOpen={isOpenDeleteContact}
          onOpenChange={onOpenChangeDeleteModal}
          proposalContact={selectedProposalContact}
        />
      )}
      <ModalCreateProposalContact
        isOpen={isOpenCreateContactModal}
        onOpenChange={onOpenChangeCreateModal}
        refreshFunc={refresh}
      />
    </Fragment>
  );
}
