import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
} from '@nextui-org/react';
import type { Dispatch, SetStateAction } from 'react';
import { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { createProposalContact } from 'src/api/proposalContact';
import type { CreateProposalContactRequest } from 'src/api/proposalContact/types';

export function ModalCreateProposalContact({
  isOpen,
  onOpenChange,
  refreshFunc,
}: {
  isOpen: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  refreshFunc: Function;
}) {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [position, setPosition] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [querying, setQuerying] = useState<boolean>(false);

  function clearInputs() {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPosition('');
  }

  async function submit() {
    try {
      setQuerying(true);

      const createProposalContactRequest: CreateProposalContactRequest = {
        name: `${firstName} ${lastName}`,
        email,
        position,
        phoneNumber,
      };

      await createProposalContact(createProposalContactRequest);
      toast.success('Proposal contact succesfully created');
      clearInputs();
      refreshFunc();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      toast.error('Error while creating proposal contact');
    } finally {
      setQuerying(false);
    }
  }

  function validate() {
    if (!firstName || !lastName || !email || !position || !phoneNumber) return true;

    return false;
  }

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {(onClose) => (
          <Fragment>
            <ModalHeader>Adding Proposal contact</ModalHeader>
            <ModalBody>
              <Input
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                type="text"
                label="First name"
                placeholder="Enter the first name"
              />
              <Input
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                type="text"
                label="Last name"
                placeholder="Enter the last name"
              />
              <Input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                label="Email"
                placeholder="Enter the email"
              />
              <Input
                onChange={(e) => setPosition(e.target.value)}
                value={position}
                type="text"
                label="Position"
                placeholder="Enter the position"
              />
              <Input
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
                type="text"
                label="Phone Number"
                placeholder="Enter the phone number"
              />
            </ModalBody>
            <ModalFooter>
              <Button variant="light" onPress={onClose}>
                Cancel
              </Button>
              <Button isDisabled={validate()} color="primary" onPress={submit}>
                {querying ? <Spinner color="white" /> : 'Create'}
              </Button>
            </ModalFooter>
          </Fragment>
        )}
      </ModalContent>
    </Modal>
  );
}
