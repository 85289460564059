import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { Spinner } from '@nextui-org/react';
import type { FC } from 'react';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteProposal } from 'src/api/proposal';

type ModalDeleteProposalProps = {
  proposalId: number;
  onDelete: VoidFunction;
};

export const ModalDeleteProposal: FC<ModalDeleteProposalProps> = function ModalDeleteProposal({
  proposalId,
  onDelete,
}) {
  const [open, setOpen] = useState<boolean>(false);
  const [querying, setQuerying] = useState<boolean>(false);
  const navigate = useNavigate();

  async function submit() {
    try {
      setQuerying(true);
      await deleteProposal(proposalId);
      onDelete();
      toast.success('Proposal successfully deleted');
      navigate('/proposal');
      setOpen(false);
    } catch (error) {
      toast.error('Error while deleting proposal');
    } finally {
      setQuerying(false);
    }
  }

  return (
    <Fragment>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>
        Delete
      </Button>
      <Dialog open={open}>
        <DialogTitle>Are you sure you want to delete this proposal?</DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={submit}>
            {querying ? <Spinner /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
