import type { InputProps } from '@nextui-org/react';
import { Input, cn } from '@nextui-org/react';

const CustomInput = (props: InputProps) => {
  const { children, ...otherProps } = props;

  return (
    <Input
      {...otherProps}
      classNames={{
        mainWrapper: cn('flex-row items-center justify-start h-14 gap-2 w-full'),
        inputWrapper: cn('h-full w-full max-w-xs'),
        label: cn('text-base text-black'),
      }}
    >
      {children}
    </Input>
  );
};

export default CustomInput;
