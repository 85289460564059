import { getToken } from '@utils/auth';
import axios from 'axios';
import type { CreateProposalContactRequest, ProposalContact } from './types';

export async function getProposalContactList() {
  try {
    const result = await axios.get<ProposalContact[]>(
      `${process.env.REACT_APP_API_BASE_URL}/proposal-contact`,
      { headers: { Authorization: `Bearer ${getToken()}` } },
    );

    return result.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
}

export async function deleteProposalContact(id: number) {
  try {
    const result = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/proposal-contact/${id}`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      },
    );

    return result.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
}

export async function createProposalContact(body: CreateProposalContactRequest) {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/proposal-contact`,
      body,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      },
    );

    return result.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
}
