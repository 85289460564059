"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureFlagFactory = void 0;
const agency_1 = require("./agency");
const generated_1 = require("./generated");
exports.FeatureFlagFactory = (0, generated_1.defineFeatureFlagFactory)({
    defaultData: async () => ({
        agency: agency_1.AgencyFactory,
        activated: true,
    }),
});
