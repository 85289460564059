import { apiRoutes } from '@services/constants';
import * as fetch from '@utils/fetch';
import type { LoginObject, LoginResponse } from './types';

export const login = async (data: LoginObject): Promise<LoginResponse> => {
  const response = await fetch.post<LoginObject, LoginResponse>(apiRoutes.login, data, {
    headers: { 'Content-Type': 'application/json' },
  });

  return {
    jwtToken: response.jwtToken,
    agencyAdmin: response.agencyAdmin,
    roles: response.roles,
  };
};
