import PageTitle from '@components/PageTitle';
import { Search } from '@mui/icons-material';
import {
  getKeyValue,
  Input,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll';
import { getIntegrations } from '@services/integrations';
import type { IntegrationObject } from '@services/integrations/types';
import dayjs from 'dayjs';
import _ from 'lodash';

import React, { type ChangeEvent } from 'react';
import { type Key, useAsyncList } from 'react-stately';

export const IntegrationsPage = () => {
  const [search, setSearch] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(false);
  const [firstLoad, setFirstLoad] = React.useState(true);

  const renderCell = React.useCallback((integration: IntegrationObject, columnKey: Key) => {
    switch (columnKey) {
      case 'visitsLastRun': {
        return integration.lastVisitSync?.runAt
          ? dayjs(integration.lastVisitSync.runAt).format('MMM D, YYYY - hh:mm a')
          : 'N/A';
      }
      case 'profilesLastRun': {
        return integration.lastProfileSync?.runAt
          ? dayjs(integration.lastProfileSync.runAt).format('MMM D, YYYY - hh:mm a')
          : 'N/A';
      }
      case 'numVisitsSynced': {
        return integration.lastVisitSync?.numSynced?.toString() ?? 'N/A';
      }
      case 'numProfilesSynced': {
        return integration.lastProfileSync?.numSynced?.toString() ?? 'N/A';
      }

      default:
        return getKeyValue(integration, columnKey.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const list = useAsyncList({
    async load({ cursor }) {
      if (cursor) {
        setIsLoading(false);
      }

      setIsLoading(true);
      setFirstLoad(true);

      const res = await getIntegrations(cursor, '25', search);

      setIsLoading(false);
      setFirstLoad(false);

      setHasMore(res.length === 25);

      return {
        items: res,
        cursor: cursor ? (parseInt(cursor, 10) + 1).toString() : '2',
      };
    },
  });

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    list.items = [];
    setIsLoading(true);
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    if (!firstLoad) {
      list.reload();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const debounceOnChange = _.debounce(handleOnChange, 500);

  const [loaderRef, scrollerRef] = useInfiniteScroll({
    hasMore,
    onLoadMore: list.loadMore,
  });

  return (
    <div>
      <div className="flex flex-row justify-between">
        <PageTitle>Integrations</PageTitle>
      </div>
      <Input
        aria-label="Search"
        size="sm"
        startContent={<Search />}
        onChange={debounceOnChange}
        placeholder="Search..."
        className="mt-8 mb-4 w-72"
      />
      <Table
        baseRef={scrollerRef}
        aria-label="Agencies table"
        isStriped
        shadow="md"
        bottomContent={
          hasMore ? (
            <div className="flex justify-center w-full">
              <Spinner ref={loaderRef} color="primary" />
            </div>
          ) : null
        }
        classNames={{
          base: 'max-h-[70vh] overflow-auto p-3',
          table: 'max-h-[50vh]',
        }}
      >
        <TableHeader
          columns={[
            {
              key: 'name',
              label: 'AGENCY',
            },
            {
              key: 'dataProvider',
              label: 'DATA PROVIDER',
            },
            {
              key: 'profilesLastRun',
              label: 'PROFILES LAST RUN AT',
            },
            {
              key: 'visitsLastRun',
              label: 'VISITS LAST RUN AT',
            },

            {
              key: 'numProfilesSynced',
              label: 'NUM PROFILES LAST SYNCED',
            },
            {
              key: 'numVisitsSynced',
              label: 'NUM VISITS LAST SYNCED',
            },
          ]}
        >
          {(column) => (
            <TableColumn className="text-white bg-purple-500" key={column.key}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          isLoading={isLoading}
          items={list.items as IntegrationObject[]}
          loadingContent={<Spinner color="secondary" />}
          emptyContent={'No rows to display.'}
        >
          {(item: IntegrationObject) => (
            <TableRow key={item.id}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
