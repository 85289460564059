"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceItemFactory = void 0;
const luxon_1 = require("luxon");
const generated_1 = require("./generated");
const invoice_1 = require("./invoice");
const today = luxon_1.DateTime.now();
function getInvoiceFactory() {
    return invoice_1.InvoiceFactory;
}
exports.InvoiceItemFactory = (0, generated_1.defineInvoiceItemFactory)({
    defaultData: async () => ({
        createdAt: today.toJSDate(),
        updatedAt: today.toJSDate(),
        invoice: getInvoiceFactory(),
    }),
});
