"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VisitFactory = void 0;
const luxon_1 = require("luxon");
const uuid_1 = require("uuid");
const agency_1 = require("./agency");
const generated_1 = require("./generated");
const profile_1 = require("./profile");
const shiftStart = luxon_1.DateTime.now().minus({ weeks: 1 });
const shiftEnd = shiftStart.plus({ hours: 8 });
exports.VisitFactory = (0, generated_1.defineVisitFactory)({
    defaultData: async () => ({
        agency: agency_1.AgencyFactory,
        profile: profile_1.ProfileFactory,
        publicId: (0, uuid_1.v4)(),
        externalVisitId: (0, uuid_1.v4)(),
        patientName: 'Joe Smith',
        startDate: shiftStart.toJSDate(),
        endDate: shiftEnd.toJSDate(),
        clockInDate: shiftStart.minus({ minutes: 2 }).toJSDate(),
        clockOutDate: shiftEnd.plus({ minutes: 3 }).toJSDate(),
        preferredClockInMethod: null,
        goodDocumentation: null,
        careLogCompleted: null,
        documentation: null,
        cancelled: false,
        totalHours: shiftEnd.diff(shiftStart, 'hours').hours.toFixed(2),
        createdAt: shiftEnd.plus({ days: 1 }).toJSDate(),
        metadata: {},
    }),
    traits: {
        cancelled: {
            data: {
                clockInDate: null,
                clockOutDate: null,
                cancelled: true,
            },
        },
    },
});
