"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgencyAdminFactory = void 0;
const uuid_1 = require("uuid");
const agency_1 = require("./agency");
const generated_1 = require("./generated");
exports.AgencyAdminFactory = (0, generated_1.defineAgencyAdminFactory)({
    defaultData: async () => ({
        publicId: (0, uuid_1.v4)(),
        pronouns: 'they/them',
        profilePictureUrl: null,
        role: 'ADMIN',
        password: '$2b$10$zH51BBwDYE8ry4CLI41ZwuiuEYmXbJU/tPmkQaEAk/qJnz8pDu2r6', // bcrypt.hash('123456', 10)
        language: 'en-us',
        agency: agency_1.AgencyFactory,
    }),
    traits: {
        super_admin: {
            data: {
                role: 'SUPER_ADMIN',
            },
        },
    },
});
