"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProposalContactFactory = void 0;
const luxon_1 = require("luxon");
const generated_1 = require("./generated");
exports.ProposalContactFactory = (0, generated_1.defineProposalContactFactory)({
    defaultData: async () => ({
        createdAt: luxon_1.DateTime.now().minus({ month: 1 }).toJSDate(),
        updatedAt: luxon_1.DateTime.now().minus({ month: 1 }).toJSDate(),
        avatarImageUrl: 'https://media.licdn.com/dms/image/D4E03AQHyJ_2noAvKhw/profile-displayphoto-shrink_800_800/0/1689040541132?e=1706745600&v=beta&t=c5ldhDIj6HnH8py1z8sv3HSGmYDlLRuDqCow_R8vtb0',
        phoneNumber: '(202) 555-0133',
    }),
});
