import Sidebar from '@components/Sidebar';
import { checkAuth } from '@utils/auth';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as S from './styles';

const App = (): JSX.Element => {
  checkAuth();

  return (
    <S.Container>
      <S.SidebarContainer>
        <Sidebar />
      </S.SidebarContainer>
      <S.PageContainer>
        <Outlet />
        <ToastContainer />
      </S.PageContainer>
    </S.Container>
  );
};

export default App;
