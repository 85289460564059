"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgencyJobPostingFactory = exports.exampleJobDescription = void 0;
const luxon_1 = require("luxon");
const agency_1 = require("./agency");
const generated_1 = require("./generated");
// Note: this is a sample job description and its long and ugly, so we ignore prettier so it stays on one line
// prettier-ignore
exports.exampleJobDescription = { blocks: [{ key: '88cdj', data: {}, text: 'Position Overview:', type: 'unstyled', depth: 0, entityRanges: [], inlineStyleRanges: [{ style: 'BOLD', length: 18, offset: 0 }], }, { key: 'emhk', data: {}, text: "We are seeking compassionate and detail-oriented Home Health Care Aides to provide personalized care and support to our clients in their homes. The ideal candidate will assist clients with daily living activities, including bathing, dressing, meal preparation, and medication management. You will play a vital role in enhancing our clients' quality of life and promoting their independence.", type: 'unstyled', depth: 0, entityRanges: [], inlineStyleRanges: [], }, { key: 'cimuk', data: {}, text: 'Requirements:', type: 'unstyled', depth: 0, entityRanges: [], inlineStyleRanges: [{ style: 'BOLD', length: 13, offset: 0 }], }, { key: '5v2p2', data: {}, text: 'Certification as a Home Health Aide or Nursing Assistant preferred.', type: 'unordered-list-item', depth: 0, entityRanges: [], inlineStyleRanges: [], }, { key: '9brrl', data: {}, text: 'Proven experience in home health care or similar role.', type: 'unordered-list-item', depth: 0, entityRanges: [], inlineStyleRanges: [], }, { key: '54v1k', data: {}, text: 'Excellent communication and interpersonal skills.', type: 'unordered-list-item', depth: 0, entityRanges: [], inlineStyleRanges: [], }, { key: '8hk8s', data: {}, text: 'Ability to work flexible hours, including evenings and weekends.', type: 'unordered-list-item', depth: 0, entityRanges: [], inlineStyleRanges: [], }, { key: '914q9', data: {}, text: 'Compassionate, patient, and possesses a strong dedication to helping others.', type: 'unordered-list-item', depth: 0, entityRanges: [], inlineStyleRanges: [], },], entityMap: {}, };
exports.AgencyJobPostingFactory = (0, generated_1.defineAgencyJobPostingFactory)({
    defaultData: async () => ({
        created: luxon_1.DateTime.now().minus({ month: 1 }).toJSDate(),
        description: exports.exampleJobDescription,
        payRate: '$15 / hour',
        resumeRequired: false,
        acceptingApplications: true,
        isDeleted: false,
        agency: agency_1.AgencyFactory,
    }),
});
