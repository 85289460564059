"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProposalFactory = void 0;
const client_1 = require("@prisma/client");
const luxon_1 = require("luxon");
const generated_1 = require("./generated");
const proposalContact_1 = require("./proposalContact");
const today = luxon_1.DateTime.now();
exports.ProposalFactory = (0, generated_1.defineProposalFactory)({
    defaultData: async () => ({
        proposalExpirationDate: today.plus({ days: 30 }).toJSDate(),
        averageMonthlyReward: 15,
        proposalLink: null,
        proposalStatus: client_1.ProposalStatus.PENDING,
        createdAt: today.toJSDate(),
        updatedAt: today.toJSDate(),
        totalEmployee: 10,
        closeDate: today.toJSDate(),
        platformFee: 11,
        finalPlatformFee: 9,
        pandadocContractLink: null,
        exclude: false,
        active: true,
        stripePaymentMethodId: null,
        pandadocContractId: null,
        signatoryEmail: null,
        signatoryName: null,
        pandadocMarketingContractLink: null,
        pandadocMarketingContractId: null,
        signedDate: null,
        newAverageMonthlyReward: null,
        newAverageMonthlyRewardStartDate: null,
        proposalBillingCadence: client_1.ProposalBillingCadence.MONTHLY,
        proposalContact: proposalContact_1.ProposalContactFactory,
    }),
});
