export const usersColumns = [
  {
    key: 'firstName',
    label: 'NAME',
  },
  {
    key: 'agencyName',
    label: 'AGENCY',
  },
  {
    key: 'phoneNumber',
    label: 'PHONE NUMBER',
  },
  {
    key: 'totalPoints',
    label: 'TOTAL POINTS',
  },
  {
    key: 'activated',
    label: 'ACTIVE',
  },
  {
    key: 'actions',
    label: 'ACTIONS',
  },
];

export const pointsEarnedColumns = [
  {
    key: 'type',
    label: 'TYPE',
  },
  {
    key: 'points',
    label: 'POINTS',
  },
  {
    key: 'awarded',
    label: 'AWARDED',
  },
];

export const pointsRedeemedColumns = [
  {
    key: 'type',
    label: 'TYPE',
  },
  {
    key: 'giftcardType',
    label: 'BRAND',
  },
  {
    key: 'giftcardValue',
    label: 'AMOUNT',
  },
  {
    key: 'points',
    label: 'POINTS',
  },
  {
    key: 'status',
    label: 'STATUS',
  },
  {
    key: 'created',
    label: 'REDEEMED',
  },
  {
    key: 'link',
    label: 'GIFT CARD LINK',
  },
];

export const GiftCardBrands: { [id: string]: string } = {
  OKMHM2X2OHYV: 'Amazon.com',
  DPIPLH0SRBO6: 'Walmart',
  '2XG0FLQXBDCZ': 'Starbucks US',
  Q24BD9EZ332JT: 'Virtual Visa',
  CDMMRJLWKW94: 'Spotify',
  '8NCJL17RGBPV': 'Hulu',
  A2J05SWPI2QG: 'Physical Visa',
  '6B8FUJ5JDUO8': 'Marshalls',
  EZ9TELXE8OYB: 'Bath & Body Works',
  GQHY7QGWSI00: "Domino's",
  '3Y1VI49CMXEC': 'Nordstrom',
  J8IF1NOYS959: 'Burger King',
  SRDHFATO9KHN: 'Target',
  W6DMKUAZR9WC: 'Marshalls',
  '2JFKPXBWDC1K': "Applebee's",
  '6NYL22SA5OJM': "Chili's",
  SJ1RGWMHZ3ZX: 'LongHorn Steakhouse',
  Q1G9VC2Q9CXK: 'Olive Garden',
  '9O0IZJV353C5': 'Bed Bath & Beyond',
  '899XF6JLQTER': 'Ulta Beauty',
  R12S6QRP70TG: 'Burlington',
  OUOC99YPZ5OB: "Lowe's",
  PJNLKSECF7GR: 'CVS Pharmacy',
  CFMAZHY7FX64: 'American Red Cross',
  Q45VPXMM09KB: 'Flemings Prime Steakhouse and Wine Bar',
  AFO794BZA8LR: 'The Home Depot',
  N7XGUA9XXFAB: 'Banana Republic',
  H3NT6D9F1F2X: 'Texas Roadhouse',
};
