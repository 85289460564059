import { toast } from 'react-toastify';
import { getToken, removeToken } from './auth';

async function http<T>(path: string, config: RequestInit): Promise<T> {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    };

    const response = await fetch(path, { ...config, headers });

    if (!response.ok) {
      if (response.status === 401 && !path.endsWith('login')) {
        removeToken();
        window.location.href = '/login';
      }

      const error = await response.json();

      return {
        code: error.statusCode,
        error: error.message,
      } as never;
    }

    return await (response.json() || null);
  } catch (error) {
    toast.error('An error occurred. Please try again later.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

    throw error;
  }
}

export function get<T>(
  path: string,
  params?: Record<string, string> | null,
  config?: RequestInit,
): Promise<T> {
  const init = { method: 'get', ...config };
  let requestPath = path;

  if (params) {
    requestPath += `?${new URLSearchParams(params).toString()}`;
  }

  return http<T>(requestPath, init);
}

export function post<T, U>(path: string, body: T, config?: RequestInit): Promise<U> {
  const init = { method: 'post', body: JSON.stringify(body), ...config };

  return http<U>(path, init);
}

export function put<T, U>(path: string, body: T, config?: RequestInit): Promise<U> {
  const init = { method: 'put', body: JSON.stringify(body), ...config };

  return http<U>(path, init);
}

export function patch<T, U>(path: string, body: T, config?: RequestInit): Promise<U> {
  const init = { method: 'PATCH', body: JSON.stringify(body), ...config };

  return http<U>(path, init);
}
