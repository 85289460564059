export const theme = {
  type: 'light',
  colors: {
    secondary: '#9f2480',
    mainBg: '#FAFAFA',
    lightBg: '#F2F2F2',
    border: '#b0b0b0',
    white: '#FAFAFA',
    black: '#1F1F1F',
    lightGray: '#EAEAEA',
    secondGray: '#f1f3f5',
    gray: '#7e868c;',
    darkGray: '#2E2F42',
    error: '#FF6347',
    primaryLight: '$green200',
    primaryLightHover: '$green300',
    primaryLightActive: '$green400',
    primaryLightContrast: '$green600',
    primary: '#7921A2',
    primaryBorder: '$green500',
    primaryBorderHover: '$green600',
    primarySolidHover: '$green700',
    primarySolidContrast: '$white',
    primaryShadow: '$green500',
    lightPink: '#F5D6F5',

    gradient: 'linear-gradient(112deg, $blue100 -25%, $pink500 -10%, $purple500 80%)',
    link: '#5E1DAD',
  },
  breakpoints: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    mobileXL: '480px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1528px',
    desktop: '2560px',
  },
  space: {},
  fonts: {},
};
