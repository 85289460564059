"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgencyRewardBalanceFactory = void 0;
const faker_1 = require("@faker-js/faker");
const client_1 = require("@prisma/client");
const agency_1 = require("./agency");
const generated_1 = require("./generated");
exports.AgencyRewardBalanceFactory = (0, generated_1.defineAgencyRewardBalanceFactory)({
    defaultData: async () => ({
        agency: agency_1.AgencyFactory,
        amount: 1000000,
        transactionType: client_1.AgencyRewardBalanceTransactionType.Credit,
        transactionAmount: 0,
        source: client_1.AgencyRewardBalanceTransactionSource.Invoice,
        idempotencyKey: faker_1.faker.string.uuid(),
    }),
});
