export enum SmsAudience {
  ALL = 'ALL',
  ENROLLED = 'ENROLLED',
  UNENROLLED = 'UNENROLLED',
  OVER_1K_POINTS = 'OVER_1K_POINTS',
  NOT_TAKEN_SURVEY_LAST_MONTH = 'NOT_TAKEN_SURVEY_LAST_MONTH',
  NOT_COMPLETED_MISSION_LAST_WEEK = 'NOT_COMPLETED_MISSION_LAST_WEEK',
  OVER_1K_POINTS_NOT_REDEEMED_LAST_MONTH = 'OVER_1K_POINTS_NOT_REDEEMED_LAST_MONTH',
}

export type SmsOption = {
  value: SmsAudience;
  description: string;
  label: string;
};
