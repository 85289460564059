import { ContentCopy } from '@mui/icons-material';
import { Button, Tooltip } from '@nextui-org/react';
import { toast } from 'react-toastify';
import type { CopyToClipboardButtonProps } from './types';

const CopyToClipboardButton = (props: CopyToClipboardButtonProps) => {
  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(props.text);
      toast.success('Tag copied to clipboard!', { autoClose: 2000, position: 'top-center' });
    } catch (error) {
      toast.error('Failed to copy tag to clipboard!');
    }
  };

  return (
    <Tooltip content="Copy tag" placement="right">
      <Button variant="light" size="sm" isIconOnly onClick={copyToClipboard}>
        <ContentCopy fontSize="small" />
      </Button>
    </Tooltip>
  );
};

export default CopyToClipboardButton;
