"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgencyRewardFactory = void 0;
const luxon_1 = require("luxon");
const generated_1 = require("./generated");
exports.AgencyRewardFactory = (0, generated_1.defineAgencyRewardFactory)({
    defaultData: async () => ({
        value: 20,
        activated: true,
        metadata: {},
        created: luxon_1.DateTime.now().toJSDate(),
        availableAt: null,
        expiresAt: null,
        agency: {
            connect: {
                publicId: 'xxx', // need to pass this from the seed
            },
        },
    }),
});
