import { SmsAudience } from '@pages/Agencies/SmsManager/NewSms/types';
import { apiRoutes } from '@services/constants';
import { getToken } from '@utils/auth';
import axios from 'axios';
import type {
  EditSmsData,
  EditSmsFormProps,
  EditSmsRequestBody,
  GetSmsData,
  NewSmsFormProps,
  NewSmsRequestBody,
} from './types';

export const scheduleNewSms = async (
  newSmsForm: NewSmsFormProps,
): Promise<{ message: string; status: string }> => {
  let filterType: string | undefined;

  if (newSmsForm.audience !== SmsAudience.ALL) {
    filterType = newSmsForm.audience;
  }

  const body: NewSmsRequestBody = {
    publicId: newSmsForm.agencyPublicId,
    message: newSmsForm.message,
    date: newSmsForm.date.toISOString(),
    filterType,
  };

  try {
    const result = await axios.post<NewSmsRequestBody, { message: string; status: string }>(
      apiRoutes.smsJob,
      body,
      { headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': 'application/json' } },
    );

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const getSmsJobs = async (publicId: string) => {
  try {
    const result = await axios.get<GetSmsData>(`${apiRoutes.smsJob}/${publicId}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });

    return result.data.smsList;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error while looking for SMS jobs: ', error);
    throw error;
  }
};

export const editSmsJob = async (smsId: string, editSmsForm: EditSmsFormProps) => {
  const body: EditSmsRequestBody = {
    message: editSmsForm.message,
    date: editSmsForm.date.toISOString(),
    audience: editSmsForm.audience,
  };

  try {
    const result = await axios.patch<EditSmsData>(`${apiRoutes.smsJob}/${smsId}`, body, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });

    return result.data.sms;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error while editing SMS job: ', error);
    throw error;
  }
};

export const deleteSmsJob = async (smsId: string) => {
  try {
    const result = await axios.delete(`${apiRoutes.smsJob}/${smsId}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error while deleting SMS job: ', error);
    throw error;
  }
};
