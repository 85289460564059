import type { ProposalContact } from 'src/api/proposalContact/types';

export enum ProposalStatus {
  OPEN = 'Open',
  SIGNED = 'Signed',
  EXPIRED = 'Expired',
  PENDING_APPROVAL = 'Pending Approval',
}

export enum Type {
  ANNUAL = 'Annual',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
}

export type Status = {
  id: number;
  text: ProposalStatus;
  rank: number;
  color: number;
};

export type ProposalObject = {
  id: number;
  implementationFee: number;
  finalImplementationFee: number;
  averageMonthlyReward: number;
  platformFeePrice: number;
  proposalExpirationDate: Date;
  totalEmployee: number;
  closeDate: Date;
  platformFee: number;
  finalPlatformFee: number;
  name: string;
  proposalLink: string;
  signatoryName: string;
  signatoryEmail: string;
  status: ProposalStatus;
  owner: string;
  affinityOpportunityId: number;
  createdAt: Date;
  updatedAt: Date;
  pandadocContractLink: string;
  agency: number;
  contact: ProposalContact;
  signedDate: Date;
  newAverageMonthlyReward: number;
  newAverageMonthlyRewardStartDate: Date;
  type: Type;
};

export type CreateProposalRequest = {
  affinityOpportunityId: number;
};

export type UpdateProposalRequest = {
  id: number;
  affinityOpportunityId?: number;
  proposalExpirationDate?: Date;
  implementationFee?: number;
  finalImplementationFee?: number;
  finalPlatformFee?: number;
  averageMonthlyReward?: number;
  totalEmployee?: number;
};

export type ApproveProposalRequest = {
  affinityOpportunityId: number;
};

export type Proposal = {
  id: number;
  name: string;
  createDate: Date;
  expireDate: Date;
};
