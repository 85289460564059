import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

export const LoginForm = styled.form`
  padding: 50px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;
  width: 500px;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet.value}) {
    width: 100%;
  }
`;

export const LoginBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    320deg,
    ${({ theme }) => theme.colors.primary} -25%,
    #222 -10%,
    ${({ theme }) => theme.colors.primary} 80%
  );
  width: 100%;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet.value}) {
    display: none;
  }
`;

export const FormError = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: 14px;
  height: 17px;
  text-align: center;
  margin: 8px 0px;
`;
