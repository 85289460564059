import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const SidebarContainer = styled.div`
  width: 20vw;
  border-right: 1px #e4e4e4 solid;
`;

export const PageContainer = styled.div`
  width: 85vw;
  height: 100vh;
  padding: 30px;
`;
