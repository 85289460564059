export type PointRewardType =
  | 'SURVEY'
  | 'QUEST'
  | 'CLOCK_IN_METHOD'
  | 'CLOCK_IN_ON_TIME'
  | 'CLOCK_IN'
  | 'BIRTHDAY'
  | 'CLOCK_OUT_ON_TIME'
  | 'CERTIFICATE_RENEWAL'
  | 'GOOD_DOCUMENTATION'
  | 'REFERRAL_REWARD'
  | 'SPOT_REWARD'
  | 'REFERRAL_SUBMISSION_REWARD'
  | 'TRAINING_PROGRESS'
  | 'CARE_LOG_COMPLETED'
  | 'FIRST_LOGIN'
  | 'HOLIDAY'
  | 'ANNIVERSARY'
  | 'MILESTONE'
  | 'WEEKEND_SHIFT'
  | 'SHIFT_DURATION'
  | 'CLOCK_IN_DISTANCE'
  | 'CLOCK_OUT_DISTANCE'
  | 'VISIT_COMPLIANCE';

export type RedeemedTypes = 'GIFTCARD' | 'TREASURE_BOX';

export const redeemedType: { [key in RedeemedTypes]: string } = {
  GIFTCARD: 'Gift card',
  TREASURE_BOX: 'Treasure box',
};

export const pointsRewardsType: { [key in PointRewardType]: string } = {
  CLOCK_IN: 'Clock in',
  CLOCK_IN_ON_TIME: 'Clock in on time',
  CLOCK_IN_METHOD: 'Clock in method',
  CLOCK_OUT_ON_TIME: 'Clock out on time',
  GOOD_DOCUMENTATION: 'Good Documentation',
  CARE_LOG_COMPLETED: 'Care log completed',
  TRAINING_PROGRESS: 'Training progress',
  CERTIFICATE_RENEWAL: 'Certificate renewal',
  BIRTHDAY: 'Birthday',
  FIRST_LOGIN: 'First Login',
  SPOT_REWARD: 'Spot reward',
  QUEST: 'Mission',
  REFERRAL_REWARD: 'Referral reward',
  REFERRAL_SUBMISSION_REWARD: 'Referral submission reward',
  HOLIDAY: 'Holiday',
  SURVEY: 'Survey',
  ANNIVERSARY: 'Anniversary',
  MILESTONE: 'New Hire Milestone',
  WEEKEND_SHIFT: 'Weekend Shift',
  SHIFT_DURATION: 'Shift Duration',
  CLOCK_IN_DISTANCE: 'Clock in distance',
  CLOCK_OUT_DISTANCE: 'Clock out distance',
  VISIT_COMPLIANCE: 'Visit Compliance',
};
