import styled from 'styled-components';

export const statusContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const statusSubTitlesContainer = styled.span`
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const statusSubTitles = styled.span`
  font-size: 18px;
  font-weight: 600;
`;

export const proposalPending = styled.span`
  background-color: #f7c325;
  border-radius: 20px;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const proposalOpen = styled.span`
  background-color: #2c88d9;
  color: white;
  font-weight: 600;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const proposalExpired = styled.span`
  background-color: #788896;
  color: white;
  font-weight: 600;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const proposalSigned = styled.span`
  background-color: #1aae9f;
  color: white;
  font-weight: 600;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const link = styled.a`
  color: blue;
  text-decoration: underline;
`;
