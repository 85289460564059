import type { SmsOption } from './SmsManager/NewSms/types';
import { SmsAudience } from './SmsManager/NewSms/types';

export const getAgencyColumns = (parameter: string) => {
  let columns = [
    {
      key: 'name',
      label: 'AGENCY',
    },
    {
      key: 'dataProvider',
      label: 'DATA PROVIDER',
    },
  ];

  if (parameter === 'active') {
    columns = [
      ...columns,
      {
        key: 'appName',
        label: 'APP NAME',
      },
      {
        key: 'launchDate',
        label: 'LAUNCH DATE',
      },
      {
        key: 'activeCaregivers',
        label: 'ACTIVE CARE TEAM',
      },
    ];
  } else if (parameter === 'preLaunch') {
    columns = [
      ...columns,
      {
        key: 'created',
        label: 'CREATED',
      },
    ];
  } else if (parameter === 'inactive') {
    columns = [
      ...columns,
      {
        key: 'launchDate',
        label: 'LAUNCH DATE',
      },
      {
        key: 'deactivatedDate',
        label: 'DEACTIVATED',
      },
    ];
  }

  columns = [
    ...columns,
    {
      key: 'actions',
      label: 'ACTIONS',
    },
  ];

  return columns;
};

export const smsOptions: SmsOption[] = [
  {
    value: SmsAudience.ALL,
    description: '',
    label: 'All',
  },
  {
    value: SmsAudience.ENROLLED,
    description: '(has logged in at least once)',
    label: 'Enrolled',
  },
  {
    value: SmsAudience.UNENROLLED,
    description: '(has never logged in)',
    label: 'Unenrolled',
  },
  {
    value: SmsAudience.OVER_1K_POINTS,
    description: '(anyone with over 1k points)',
    label: 'Over 1k points',
  },
  {
    value: SmsAudience.NOT_TAKEN_SURVEY_LAST_MONTH,
    description: '(no survey in the last month)',
    label: 'Not taken a survey in the last month',
  },
  {
    value: SmsAudience.NOT_COMPLETED_MISSION_LAST_WEEK,
    description: '(no mission in the last week)',
    label: 'Not completed a mission in the last week',
  },
  {
    value: SmsAudience.OVER_1K_POINTS_NOT_REDEEMED_LAST_MONTH,
    description: '(1k points, no gift card in a month.)',
    label: "Has 1k points and hasn't redeemed a gift card in a month",
  },
];

export const smsAudienceTextMap = {
  [SmsAudience.ALL]: 'All',
  [SmsAudience.ENROLLED]: 'Enrolled',
  [SmsAudience.UNENROLLED]: 'Unenrolled',
  [SmsAudience.NOT_COMPLETED_MISSION_LAST_WEEK]: 'Not Completed mission last week',
  [SmsAudience.NOT_TAKEN_SURVEY_LAST_MONTH]: 'Not taken survey last month',
  [SmsAudience.OVER_1K_POINTS]: 'Over 1k points',
  [SmsAudience.OVER_1K_POINTS_NOT_REDEEMED_LAST_MONTH]: 'Over 1k points not redeemed last month',
};
