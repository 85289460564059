import type { RadioProps } from '@nextui-org/react';
import { Radio, cn } from '@nextui-org/react';

const CustomRadio = (props: RadioProps) => {
  const { children, ...otherProps } = props;

  return (
    <Radio
      {...otherProps}
      classNames={{
        labelWrapper: cn('flex-row gap-2 items-center'),
      }}
    >
      {children}
    </Radio>
  );
};

export default CustomRadio;
