import { apiRoutes } from '@services/constants';
import { get } from '../../utils/fetch';
import type { IntegrationObject, IntegrationsResponse } from './types';

export const getIntegrations = async (
  cursor: any,
  limit: string,
  filter?: string,
): Promise<IntegrationObject[]> => {
  const response = await get<IntegrationsResponse>(apiRoutes.integrations.getAll, {
    page: cursor,
    limit,
    filter: filter || '',
  });

  return response.agencies;
};
