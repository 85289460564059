"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Factory = void 0;
const agency_1 = require("./agency");
const agencyAdmin_1 = require("./agencyAdmin");
const agencyJobPosting_1 = require("./agencyJobPosting");
const agencyLocation_1 = require("./agencyLocation");
const agencyReward_1 = require("./agencyReward");
const agencyRewardBalance_1 = require("./agencyRewardBalance");
const featureFlag_1 = require("./featureFlag");
const generated_1 = require("./generated");
const giftCardRedeem_1 = require("./giftCardRedeem");
const group_1 = require("./group");
const invoice_1 = require("./invoice");
const invoiceItem_1 = require("./invoiceItem");
const invoicingConfiguration_1 = require("./invoicingConfiguration");
const pointReward_1 = require("./pointReward");
const profile_1 = require("./profile");
const proposal_1 = require("./proposal");
const proposalContact_1 = require("./proposalContact");
const referral_1 = require("./referral");
const rewardsBankTransactions_1 = require("./rewardsBankTransactions");
const role_1 = require("./role");
const visit_1 = require("./visit");
var Factory;
(function (Factory) {
    Factory.Agency = agency_1.AgencyFactory;
    Factory.AgencyAdmin = agencyAdmin_1.AgencyAdminFactory;
    Factory.AgencyJobPosting = agencyJobPosting_1.AgencyJobPostingFactory;
    Factory.AgencyLocation = agencyLocation_1.AgencyLocationFactory;
    Factory.AgencyReward = agencyReward_1.AgencyRewardFactory;
    Factory.AgencyRewardBalance = agencyRewardBalance_1.AgencyRewardBalanceFactory;
    Factory.FeatureFlag = featureFlag_1.FeatureFlagFactory;
    Factory.GiftCardRedeem = giftCardRedeem_1.GiftCardRedeemFactory;
    Factory.Group = group_1.GroupFactory;
    Factory.Invoice = invoice_1.InvoiceFactory;
    Factory.InvoiceItem = invoiceItem_1.InvoiceItemFactory;
    Factory.InvoiceConfiguration = invoicingConfiguration_1.InvoiceConfigurationFactory;
    Factory.PointReward = pointReward_1.PointRewardFactory;
    Factory.Profile = profile_1.ProfileFactory;
    Factory.Proposal = proposal_1.ProposalFactory;
    Factory.ProposalContact = proposalContact_1.ProposalContactFactory;
    Factory.Referral = referral_1.ReferralFactory;
    Factory.RewardsBankTransactions = rewardsBankTransactions_1.RewardsBankTransactionsFactory;
    Factory.Role = role_1.RoleFactory;
    Factory.Visit = visit_1.VisitFactory;
    function init(prisma) {
        (0, generated_1.initialize)({ prisma });
    }
    Factory.init = init;
})(Factory || (exports.Factory = Factory = {}));
