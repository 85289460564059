"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileFactory = void 0;
const client_1 = require("@prisma/client");
const luxon_1 = require("luxon");
const uuid_1 = require("uuid");
const agency_1 = require("./agency");
const generated_1 = require("./generated");
const today = luxon_1.DateTime.now();
const birthday = today.minus({ years: 25 });
exports.ProfileFactory = (0, generated_1.defineProfileFactory)({
    defaultData: async () => ({
        agency: agency_1.AgencyFactory,
        publicId: (0, uuid_1.v4)(),
        language: 'en',
        timezone: 'America/New_York',
        pronouns: 'they/them',
        profilePictureUrl: '',
        registered: false,
        totalPoints: 0,
        created: today.toJSDate(),
        birthday: birthday.toJSDate(),
        onboardingDate: today.toJSDate(),
        offboardingDate: null,
        completedClasses: 13,
        totalClasses: 14,
        lastFeedVisit: today.toJSDate(),
        lastLoginDate: today.toJSDate(),
        bankLinked: false,
        bankLinkToken: null,
        bankLinkId: null,
        ewaStatus: 'setup_incomplete',
        showBankingTab: false,
        transactionsLocked: false,
        maxBalance: 119980,
        earnedThisWeek: 0,
        activated: true,
        workedHours: new client_1.Prisma.Decimal(0),
        dueClasses: null,
        discipline: 'PTA',
        employmentType: '',
        firstLoginDate: today.toJSDate(),
        activationDate: today.toJSDate(),
        metadata: {},
        jobTitle: null,
        freezed: false,
    }),
});
