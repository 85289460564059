import { Visibility } from '@mui/icons-material';
import {
  Button,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  getKeyValue,
} from '@nextui-org/react';
import { generateLink, generateStatusColor } from '@pages/Proposal/utils';
import type { ProposalObject } from '@services/proposals/types';
import { ProposalStatus } from '@services/proposals/types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

export function ProposalTable({ proposalList }: { proposalList: ProposalObject[] }) {
  const navigate = useNavigate();

  function openProposalDetails(proposal: ProposalObject) {
    navigate(`/proposal/${proposal.affinityOpportunityId}`, { state: proposal });
  }

  return (
    <Table aria-label="Agencies table" isStriped shadow="md">
      <TableHeader>
        <TableColumn className="bg-purple-500 text-white">Name</TableColumn>
        <TableColumn className="bg-purple-500 text-white">Proposal Expiration Date</TableColumn>
        <TableColumn className="bg-purple-500 text-white">Proposal Link</TableColumn>
        <TableColumn className="bg-purple-500 text-white">Status</TableColumn>
        <TableColumn className="bg-purple-500 text-white">Owner</TableColumn>
        <TableColumn className="bg-purple-500 text-white">Actions</TableColumn>
      </TableHeader>
      <TableBody
        isLoading={undefined}
        loadingContent={<Spinner color="secondary" />}
        emptyContent={'No rows to display.'}
      >
        {proposalList.map((proposal) => (
          <TableRow key={`proposal-${proposal}`}>
            <TableCell>{getKeyValue(proposal, 'name')}</TableCell>
            <TableCell>
              {moment(getKeyValue(proposal, 'proposalExpirationDate')).format('MMM DD, YYYY')}
            </TableCell>
            <TableCell>
              {proposal.status !== ProposalStatus.PENDING_APPROVAL &&
                generateLink(proposal.proposalLink)}
            </TableCell>
            <TableCell>{generateStatusColor(proposal.status)}</TableCell>
            <TableCell>{getKeyValue(proposal, 'owner')}</TableCell>
            <TableCell>
              <Button onClick={() => openProposalDetails(proposal)}>
                <Visibility />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
