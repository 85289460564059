"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RewardsBankTransactionsFactory = void 0;
const faker_1 = require("@faker-js/faker");
const agency_1 = require("./agency");
const generated_1 = require("./generated");
exports.RewardsBankTransactionsFactory = (0, generated_1.defineRewardsBankTransactionsFactory)({
    defaultData: async () => ({
        agency: agency_1.AgencyFactory,
        type: 'INCOMING',
        description: faker_1.faker.string.uuid(),
        amount: 10000,
        fee: 100,
    }),
});
