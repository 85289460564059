import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import { deactivateUser } from '@services/users';
import type { UsersObject } from '@services/users/types';
import { toast } from 'react-toastify';

interface IDeactivateUserModalProps {
  user: UsersObject;
  isOpen: boolean;
  onClose: () => void;
}

export const DeactivateUserModal = ({ user, isOpen, onClose }: IDeactivateUserModalProps) => {
  const handleConfirm = () => {
    deactivateUser(user.publicId)
      .then((response) => {
        if (response.status === 'OK') {
          toast.success('User deactivated successfully');
          onClose();
        } else toast.error('An error occurred');
      })
      .catch(() => {
        toast.error('An error occurred');
      });
  };

  return (
    <Modal size="md" isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        {() => (
          <>
            <ModalHeader className="flex flex-row gap-1 font-medium">
              Deactivate
              <span className="font-semibold">
                {user.firstName} {user.surname}
              </span>
            </ModalHeader>
            <ModalBody>
              <span>Are you sure you want to deactivate this user?</span>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onClick={onClose}>
                Close
              </Button>
              <Button color="secondary" onPress={handleConfirm}>
                Confirm
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
