"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PointRewardFactory = void 0;
const luxon_1 = require("luxon");
const uuid_1 = require("uuid");
const agency_1 = require("./agency");
const generated_1 = require("./generated");
const profile_1 = require("./profile");
const createdDate = luxon_1.DateTime.now().minus({ days: 2 }).toJSDate();
const awardedDate = luxon_1.DateTime.now().minus({ days: 1 }).toJSDate();
exports.PointRewardFactory = (0, generated_1.definePointRewardFactory)({
    defaultData: async () => ({
        agency: agency_1.AgencyFactory,
        profile: profile_1.ProfileFactory,
        publicId: (0, uuid_1.v4)(),
        created: createdDate,
        awarded: awardedDate,
        toBeClaimed: false,
    }),
});
