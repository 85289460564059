import styled from 'styled-components';

export const DropdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const DropdownText = styled.span`
  margin-right: 10px;
`;
