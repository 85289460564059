"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgencyLocationFactory = void 0;
const uuid_1 = require("uuid");
const generated_1 = require("./generated");
exports.AgencyLocationFactory = (0, generated_1.defineAgencyLocationFactory)({
    defaultData: async () => ({
        publicId: (0, uuid_1.v4)(),
        isActive: true,
        isFromEmr: false,
        agency: {
            connect: {
                publicId: 'xxx', // need to pass this from the seed
            },
        },
    }),
});
