"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceFactory = void 0;
const luxon_1 = require("luxon");
const agency_1 = require("./agency");
const generated_1 = require("./generated");
const today = luxon_1.DateTime.now();
exports.InvoiceFactory = (0, generated_1.defineInvoiceFactory)({
    defaultData: async () => ({
        stripeInvoiceId: 'in_xxxxxxxxxxxxxxxxxxxxxxxx',
        createdAt: today.toJSDate(),
        updatedAt: today.toJSDate(),
        agencyInvoiceDate: today.toFormat('m-yyyy-d'),
        status: 'open',
        agency: agency_1.AgencyFactory,
    }),
});
