import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
`;

export const ItemsContainer = styled.div`
  display: flex
  flex-direction: column;
  margin-top: 2rem;
  padding-left: 1.5rem;
`;

export const Item = styled.div`
  font-size: 1.2rem;
  padding: 0.1rem 1rem;
  margin: 2rem 1rem;
  cursor: pointer;
  &.active {
    border-left: 5px solid #7c62ac;
    color: #7c62ac;
    padding-left: calc(1rem - 5px);
  }
`;
