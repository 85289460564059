import * as fetch from '@utils/fetch';

export const getStatusCount = <T>(
  apiRoute: string,
  selectedAgency: string,
  filter?: string,
): Promise<T> =>
  fetch.get<T>(`${apiRoute}/statusCount`, {
    filter: filter ? filter.toLowerCase() : '',
    agency: selectedAgency,
  });
