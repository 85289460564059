"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceConfigurationFactory = void 0;
const client_1 = require("@prisma/client");
const luxon_1 = require("luxon");
const agency_1 = require("./agency");
const generated_1 = require("./generated");
const today = luxon_1.DateTime.now();
exports.InvoiceConfigurationFactory = (0, generated_1.defineInvoicingConfigurationFactory)({
    defaultData: async () => ({
        invoicingCadence: client_1.InvoicingCadence.Monthly,
        targetRewardBudgetCents: 100000,
        rewardsBudgetPerEmployeeCents: 1000,
        platformFeePerUserCents: 1000,
        updatedAt: today.toJSDate(),
        createdAt: today.toJSDate(),
        agency: agency_1.AgencyFactory,
    }),
});
