"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgencyFactory = void 0;
const luxon_1 = require("luxon");
const uuid_1 = require("uuid");
const generated_1 = require("./generated");
exports.AgencyFactory = (0, generated_1.defineAgencyFactory)({
    defaultData: async () => ({
        pointName: 'Perry Points',
        pointSvgPath: '/team-select/team-select-icon.svg',
        dataProvider: 'WellSky',
        engagementSurveyUrl: 'https://pyembf5qbrc.typeform.com/to/rekmzxo3',
        appUrl: 'https://mobile.joinperry.app',
        dashboardUrl: 'http://127.0.0.1:3000',
        referralSteps: ['SUBMITTED', 'HIRED', 'MILESTONE'],
        email: 'hello@joinperry.com',
        baseGiftcardPrice: 1000,
        referralArchiveTimeInDays: 30,
        clockInStreakPoints: 3,
        weeklyHoursStreakPoints: 3,
        acceptableDelayInMinutes: 7,
        scrappingSkipStartUtc: 4,
        averageVisitHours: 25,
        payPeriodInterval: '2weeks',
        balance: 0,
        averageWage: 14,
        treasureBoxPrice: 1600,
        treasureBoxConfig: {
            Target: { prices: [5], weights: [3], customName: 'Target Gift Card' },
            Walmart: { prices: [15], weights: [1], customName: 'Walmart Gift Card' },
            'Amazon.com': { prices: [15], weights: [1], customName: 'Amazon Gift Card' },
            'Starbucks US': { prices: [5], weights: [3], customName: 'Starbucks Gift Card' },
            'Virtual Visa': { prices: [10], weights: [2], customName: 'Visa Card' },
        },
        publicId: (0, uuid_1.v4)(),
        created: luxon_1.DateTime.now().minus({ month: 1 }).toJSDate(),
        launchDate: luxon_1.DateTime.now().minus({ days: 15 }).toJSDate(),
        increaseAccountNumberId: null,
        hasOpenShiftsManagement: false,
        trainingDataProvider: 'Empty',
        averageReimbursementRate: 30,
        scrappingSkipEndUtc: 10,
        referralsEnabled: true,
        timezone: 'America/New_York',
        payPeriodStart: luxon_1.DateTime.fromObject({ month: 1, day: 1 }).toFormat('yyyy-MM-dd'),
        lastUpdate: luxon_1.DateTime.now().minus({ month: 1 }).toJSDate(),
        deactivatedDate: null,
        agencyPlatformPercentageDiscount: 0,
    }),
});
