import { getToken } from '@utils/auth';
import axios from 'axios';
import type { CreateAgencyRequest } from './types';

export async function createAgency(body: CreateAgencyRequest) {
  try {
    const result = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/agency/create`, body, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });

    return result.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
}
