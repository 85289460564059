"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Db = exports.Factory = void 0;
const client_1 = require("@prisma/client");
__exportStar(require("@prisma/client"), exports);
var factories_1 = require("./factories");
Object.defineProperty(exports, "Factory", { enumerable: true, get: function () { return factories_1.Factory; } });
function getPrismaClient() {
    if (process.env.NODE_ENV === 'development') {
        if (global._prismaClient_ == null) {
            global._prismaClient_ = Db.attachExtendedProperties(new client_1.PrismaClient());
        }
        return global._prismaClient_;
    }
    return Db.attachExtendedProperties(new client_1.PrismaClient());
}
class Db {
    ref;
    static self;
    static get instance() {
        if (this.self == null) {
            this.self = new Db();
        }
        return this.self.ref;
    }
    constructor(ref = getPrismaClient()) {
        this.ref = ref;
    }
}
exports.Db = Db;
(function (Db) {
    function attachExtendedProperties(client) {
        return client.$extends({
            result: {
                visit: {
                    /**
                     * The duration of the visit in milliseconds
                     */
                    duration: {
                        needs: { clockInDate: true, clockOutDate: true },
                        compute(visit) {
                            if (visit.clockInDate == null || visit.clockOutDate == null) {
                                return null;
                            }
                            return visit.clockOutDate.valueOf() - visit.clockInDate.valueOf();
                        },
                    },
                },
            },
        });
    }
    Db.attachExtendedProperties = attachExtendedProperties;
})(Db || (exports.Db = Db = {}));
