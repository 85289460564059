export enum SmsStatus {
  SCHEDULED = 'SCHEDULED',
  FAILED = 'FAILED',
  SENT = 'SENT',
}

export enum SmsColumnsKeys {
  MESSAGE = 'message',
  AUDIENCE = 'audience',
  DATE = 'date',
  STATUS = 'status',
  ACTIONS = 'actions',
}

export type SmsItem = {
  jobId: string;
  date: string;
  message: string;
  publicId: string;
  status: string;
  filterType?: string;
};
