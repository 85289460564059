"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GiftCardRedeemFactory = void 0;
const luxon_1 = require("luxon");
const uuid_1 = require("uuid");
const generated_1 = require("./generated");
const profile_1 = require("./profile");
exports.GiftCardRedeemFactory = (0, generated_1.defineGiftCardRedeemFactory)({
    defaultData: async () => ({
        profile: profile_1.ProfileFactory,
        publicId: (0, uuid_1.v4)(),
        type: 'GIFTCARD',
        giftcardType: (0, uuid_1.v4)(),
        points: 1000,
        status: 'COMPLETED',
        created: luxon_1.DateTime.now().toJSDate(),
        giftcardValue: 500,
        externalId: (0, uuid_1.v4)(),
        link: null,
    }),
});
